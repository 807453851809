import zhCN from 'ant-design-vue/es/locale/zh_CN'
import { defineComponent, onMounted, ref } from 'vue'
import { getUrlParam } from 'wd-platform-utils'
import { currentUser } from '@/service/userService'
import { useLoadingStore } from '@/store/modules/loading'
import { useUserStore } from './store/modules/user'
import { whiteUrlList } from './router'
import { isMobile } from './utils/request'
export default defineComponent({
    name: 'App',
    setup () {
        const loadingStore = useLoadingStore()
        const spinLoading = ref(false)
        const isMoble = ref(isMobile())
        loadingStore.$subscribe((_: any, state) => {
            spinLoading.value = state.loading
        })
        const getUserInfo = async () => {
            const resultData = await currentUser()
            if (resultData) {
                useUserStore().setUserInfo(resultData.result)
            }
        }
        onMounted(() => {
            // 刷新的时候获取用户信息
            if (!whiteUrlList.includes(location.pathname) && useUserStore().getUserToken && !getUrlParam('ticket') && getUrlParam('appCode') !== 'teacher_client') {
                getUserInfo()
            }
        })
        return {
            zhCN,
            isMoble,
            spinLoading
        }
    }
})
