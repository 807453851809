/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { GetwayEroor, ResponseStatus } from '@/enmu/http'
import { router } from '@/router'
import { useUserStore } from '@/store/modules/user'
import axios, { AxiosRequestConfig, AxiosResponse, AxiosRequestHeaders } from 'axios'
import { sysMsg } from 'wd-platform-library'

// 默认配置
const defaultConfig = {
    baseURL: process.env.VUE_APP_URL,
    timeout: Number(process.env.VUE_APP_REQUEST_TIME_OUT),
    access: true,
    changeOrigin: true,
    withCredentials: true,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'content-encoding': 'gzip'
    }
}
const _axios = axios.create(defaultConfig)

// 请求拦截器
_axios.interceptors.request.use((config: AxiosRequestConfig) => {
    const userStore = useUserStore()
    if (userStore.getUserToken) {
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${userStore.getUserToken}`
        }
    }
    return config
}, error => {
    Promise.reject(error)
})

// 响应拦截器
_axios.interceptors.response.use((res: AxiosResponse) => {
    if (res.data.code !== ResponseStatus.SUCCESS) {
        if (res.status in ResponseStatus || res.data.code in ResponseStatus) {
            router.push('/error/' + res.data.code || ResponseStatus.NOT_AUTHORIZED.toString())
        } else if (res.data.code in GetwayEroor) {
            useUserStore().setToken(null)
            router.push('/login')
        } else {
            sysMsg.errorMsg(res.data.message)
        }
        return Promise.reject(res)
    }
    return res
}, error => {
    // 此处发送请求日志到graylog系统
    const res = error.response
    if (res.status === 500) {
        sysMsg.errorMsg(res.data.message || '网络错误,请联系系统管理员!')
        router.push('/error/500')
    } else {
        sysMsg.errorMsg(res.data.error || res.data.message)
        if (error.response.status in ResponseStatus) {
            router.push('/error/' + error.response.status || ResponseStatus.NOT_AUTHORIZED.toString())
        }
    }
    return Promise.reject(error)
})

const wrapReauest = (config: AxiosRequestConfig): Promise<any> => {
    return new Promise(resolve => {
        _axios(config)
            .then(res => {
                resolve([res.data, null])
            })
            .catch(err => {
                resolve([null, err.message])
            })
    })
}

const wdAxios = {
    get: (url: string, params?: any, headers?: AxiosRequestHeaders): Promise<any> => {
        return wrapReauest({ url, params, headers, method: 'GET' })
    },
    post: (url: string, data?: any, headers?: AxiosRequestHeaders) => {
        return wrapReauest({ url, data, headers, method: 'POST' })
    }
}
export default wdAxios
