import { Button, Layout, Carousel, Menu, Spin, Row, Empty, Col, Pagination, Form, ConfigProvider, Tooltip, DatePicker, Input, Select, Checkbox, Tree, Modal, Collapse, Radio, Cascader, TreeSelect, Tabs, Badge, Avatar, Dropdown } from 'ant-design-vue'
export const setupAntd: any = (app: any): void => {
    app.use(Button)
    app.use(Menu)
    app.use(Layout)
    app.use(Badge)
    app.use(Avatar)

    app.use(Empty)

    app.use(Spin)
    app.use(Row)
    app.use(Col)
    app.use(Carousel)
    app.use(Pagination)
    app.use(Form)
    app.use(Input)
    app.use(Tree)
    app.use(Radio)
    app.use(Select)
    app.use(Checkbox)
    app.use(Modal)
    app.use(Collapse)
    app.use(Cascader)
    app.use(TreeSelect)
    app.use(DatePicker)
    app.use(Tooltip)
    app.use(Dropdown)
    app.use(Tabs)
    app.use(ConfigProvider)
}
