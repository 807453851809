import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_ctx.isMoble)
    ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
    : (_openBlock(), _createBlock(_component_a_config_provider, {
        key: 1,
        locale: _ctx.zhCN
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_spin, {
            spinning: _ctx.spinLoading,
            tip: "加载中...",
            size: "large",
            wrapperClassName: "whov"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["spinning"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["locale"]))
}