import { defineStore } from 'pinia'
import { changeUserOrg } from '@/service/userService'
export const useUserStore = defineStore('user', {
    state: () => ({
        token: null as (string | null),
        userInfo: null as (User | null),
        clientType: 'yzj',
        tokenProduct: null as (string | null),
        tokencis: null as (string | null)
    }),
    getters: {
        getUserToken: state => {
            return state.token || localStorage.getItem('wd_fant_auth')
        },
        getUserInfo: state => {
            return state.userInfo
        },
        getClientType: () => {
            return localStorage.getItem('clientType')
        }
    },
    actions: {
        setClientType (clientType = 'yzj'): void {
            localStorage.setItem('clientType', clientType)
            this.clientType = clientType
        },
        async changeOrgId (orgId:string) {
            await changeUserOrg({
                orgId
            })
        },
        setToken (token: string | null): void {
            token ? localStorage.setItem('wd_fant_auth', token) : localStorage.removeItem('wd_fant_auth')
            this.token = token
        },
        setCisProductToken (token: string | null): void {
            token ? localStorage.setItem('tokenProduct', token) : localStorage.removeItem('tokenProduct')
            this.tokenProduct = token
        },
        setCisJwToken (token: string | null): void {
            token ? localStorage.setItem('tokencis', token) : localStorage.removeItem('tokencis')
            this.tokencis = token
        },
        setUserInfo (user: User | null): void {
            window.globalData = {
                USER_INFO: user
            }
            this.userInfo = user
        }
    }

})
