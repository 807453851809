import { defineStore } from 'pinia'

export const useLoadingStore = defineStore('loading', {
    state: () => ({
        loading: false as boolean
    }),
    getters: {
        getLoadingState (): boolean {
            return this.loading
        }
    },
    actions: {
        setLoadingState (loading: boolean): void {
            this.loading = loading
        }
    }

})
