import userApi from '@/api/user'
import axios from '@/config/axios'
import { checkResult, dealResult } from '@/utils/request'
import { sysMsg } from 'wd-platform-library'
import appApi from '@/api/app'

/**
 * 获取当前登录的用户信息
 * @returns 当前登录用户信息
 */
export const currentUser = async (): Promise<UserResult | null> => {
    const [result, err] = await axios.get(userApi.currentUserInfo)
    return dealResult<UserResult>(err, result, sysMsg)
}

/**
 * 切换机构
 * @param param 机构ID
 * @returns 切换结果
 */
export const changeUserOrg = async (param: { orgId: string }): Promise<string | null> => {
    const [result, err] = await axios.post(userApi.userchangeOrg, param)
    return Promise.resolve(checkResult(err, result, sysMsg))
}

/**
 * 获取用户菜单与资源组权限
 * @param appId 应用ID
 * @returns 菜单与资源组集合
 */
export const getAuthMenuList = async (param: { appId: string }): Promise<any | null> => {
    const [result, err] = await axios.get(appApi.getAuthMenuList.url, param)
    return Promise.resolve(checkResult(err, result, sysMsg))
}

/**
 * 获取cis老项目的用户菜单与资源组权限
 * @param appId 应用ID（注意，cis的appId分别是500050615和500042121，与crm的有差异）
 * @returns 菜单与资源组集合
 */
export const getCisAuthMenuList = async (param: { appId: string }): Promise<any | null> => {
    const [result, err] = await axios.get(appApi.getCisAuthMenuList.url, param)
    return Promise.resolve(checkResult(err, result, sysMsg))
}
