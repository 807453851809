/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
 * 处理增、删、改调用接口的返回结果，当出现错误自动提示，否则返回成功的结果
 * @param err 错误
 * @param result 接口返回结果
 * @param resolve Promise回调
 */
export const checkResult = (err: any, result: any, sysMsg: any) => {
    if (!err && result && result.code === 1) {
        return result.result
    } else {
        if (!err) {
            sysMsg && sysMsg.errorMsg(result.message)
        }
        return null
    }
}

/**
 * 处理获取列表或者详情返回结果
 * @param err 错误
 * @param result 接口返回结果
 * @param resolve Promise回调
 */
export const dealResult = <T extends { code: number, message: string }>(err: any, result: T, sysMsg: any, extChangeDataFun?: (params:T)=>T): T | null => {
    if (!err && result && result.code === 1) {
        result = (extChangeDataFun && extChangeDataFun(result)) || result
        return result
    } else {
        if (!err && result) {
            sysMsg && sysMsg.errorMsg(result.message)
        }
        return null
    }
}

export const isMobile = () => {
    const u = navigator.userAgent
    if (u.includes('Android') || u.includes('Linux')) {
        return true
    } else if (u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
        return true
    } else if (u.includes('iPad')) {
        return true
    }
    return false
}
