/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { App } from 'vue'
import {
    Header,
    Column,
    Table as VxeTable,
    Grid,
    Toolbar,
    Checkbox as vxeCheckBox,
    CheckboxGroup as vxeCheckboxGroup,
    Filter,
    Pager as VxePager,
    Tooltip as VxeTooltip,
    Icon as vxeIcon,
    Button as VxeButton,
    Select as VxeSelect,
    Modal as vxeModal,
    Input as vxeInput
    , VXETable
} from 'vxe-table'
import zhCN from 'vxe-table/es/locale/lang/zh-CN'
import XEUtils from 'xe-utils'
VXETable.setup({
    i18n: (key: any, args: any) => XEUtils.toFormatString(XEUtils.get(zhCN, key), args)
})

export const setupVxeTable = (app: App) => {
    app.use(vxeIcon)
    app.use(Header)
    app.use(Column)
    app.use(Grid)
    app.use(vxeCheckBox)
    app.use(vxeCheckboxGroup)
    app.use(Toolbar)
    app.use(VxeTable)
    app.use(VxeTooltip)
    app.use(VxeButton)
    app.use(vxeModal)
    app.use(vxeInput)
    app.use(VxePager)
    app.use(VxeSelect)
    app.use(Filter)
}
