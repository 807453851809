import { isMobile } from './request'

(function flexible (window, document) {
    const docEl = document.documentElement
    const dpr = window.devicePixelRatio || 1
    const baseSize = 100
    // set 1rem = viewWidth / 10
    function setRemUnit () {
        // 当前页面宽度相对于 1920 宽的缩放比例，可根据自己需要修改。
        let scale = 0
        let resultScale = 0
        if (isMobile()) {
            scale = document.documentElement.clientWidth / 375
            // 最小375
            scale = scale <= 1 ? 1 : scale
            resultScale = baseSize * scale
        } else {
            scale = document.documentElement.clientWidth / 1920
            // 最小1920
            scale = scale <= 0.712 ? 0.712 : scale
            // 设置页面根节点字体大小
            resultScale = (baseSize * Math.min(scale, 0.95))
        }
        docEl.style.fontSize = resultScale + 'px'
    }

    setRemUnit()

    // reset rem unit on page resize
    window.addEventListener('resize', setRemUnit)
    window.addEventListener('pageshow', function (e) {
        if (e.persisted) {
            setRemUnit()
        }
    })

    // detect 0.5px supports
    if (dpr >= 2) {
        const fakeBody = document.createElement('body')
        const testElement = document.createElement('div')
        testElement.style.border = '.5px solid transparent'
        fakeBody.appendChild(testElement)
        docEl.appendChild(fakeBody)
        if (testElement.offsetHeight === 1) {
            docEl.classList.add('hairlines')
        }
        docEl.removeChild(fakeBody)
    }
}(window, document))
