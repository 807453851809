// 错误状态码枚举
export enum ResponseStatus {
    SUCCESS = 1,
    BAD_REQUEST = 400,
    NOT_AUTHORIZED = 401,
    NOT_FOUNT = 404,
    INTERNAL_EROOR = 500,
}

// 网关状态码
export enum GetwayEroor {
    TOKEN_NOT_FOUNT = 401,
    TOKEN_ERROR = 402,
    TOKEN_TIME_OUT = 403,
    TOKEN_INVALID = 10003,
}
