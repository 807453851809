import { defineStore } from 'pinia'

export const useMenuStore = defineStore('menu', {
    state: () => ({
        menuList: [] as (MenuList[] | null)
    }),
    getters: {
        getMenuList (): MenuList[] | null {
            return this.menuList
        }
    },
    actions: {
        setMenuList (menuList: MenuList | null): void {
            const tempMenu = JSON.parse(JSON.stringify(this.menuList)) as MenuList[]
            if (menuList && menuList.menuCode) {
                if (tempMenu.findIndex(el => el.menuCode === menuList.menuCode) === -1) {
                    tempMenu.push(menuList)
                }
            }
            this.menuList = tempMenu
        }
    }

})
